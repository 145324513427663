import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import styles from './style.module.scss'
import logoDark from '@assets/images/logoDark.svg'
// import sgMedia from '@assets/images/placeholder/SGMEDIA.svg'
import useGoogleIdentify from '@shared/hooks/useGoogleIdentify'
import { getToken } from '@shared/libs/token'
import MobileMenu from '@shared/components/mobileMenu'
// import useWindowSize from '@shared/hooks/windowSize'
import { HamburgerIcon } from '../ctIcons'
import sgMedia from '@assets/images/placeholder/SGMEDIA-LOGO.svg'
import CDropdown from './headerMenu/customDropdown'
import { getHeaderSidebarMenu } from '@shared-submodule/sm-libs/menu'
const DarkModeButton = dynamic(() => import('@shared/components/header/darkModeButton'), { ssr: false })
const HeaderMenu = dynamic(() => import('@shared/components/header/headerMenu'))
const MyImage = dynamic(() => import('@shared/components/myImage'))
const CustomLink = dynamic(() => import('@shared/components/customLink'))
const HeaderUser = dynamic(() => import('./headerUser'), { ssr: false })

function MainHeader() {
  const data = [
    {
      sTitle: 'Cricket',
      sSlug: '/'
    },
    {
      sTitle: 'Football',
      sSlug: '/football',
      isAnchor: true
    },
    {
      sTitle: 'Tennis',
      sSlug: '/tennis',
      isAnchor: true
    },
    // {
    //   sTitle: 'Olympics',
    //   sSlug: '/olympics'
    // },
    {
      sTitle: 'Hockey',
      sSlug: '/hockey'
    }
    // {
    //   sTitle: 'GCL',
    //   sSlug: '/chess-2024'
    // }
  ]
  const sidebarMenu = getHeaderSidebarMenu()
  const allSports = sidebarMenu?.length ? sidebarMenu?.map((data) => ({ sTitle: data?.sName, ...data })) : []
  // const [width] = useWindowSize()
  const token = getToken()
  const router = useRouter()
  useGoogleIdentify(token)
  return (
    <>
      <div
        id="g_id_onload"
        data-type="onload"
        data-shape="rectangular"
        data-theme="filled_blue"
        data-auto_prompt="true"
        style={{ position: 'fixed', top: 0, right: 0, width: token ? 0 : 'auto', zIndex: 999999 }}
      />
      <header className={styles.siteHeader}>
        <Container>
          <Navbar className={`${styles.navbar} align-items-center`}>
            <CustomLink prefetch={false} href="/">
              <a className={`${styles.logo} navbar-brand d-block m-0 p-0`}>
                <MyImage priority src={logoDark} alt="logo" layout="responsive" />
              </a>
            </CustomLink>
            {/* <div className={styles.sgmedia}>
            <MyImage src={sgMedia} alt="SG media" layout="responsive" />
            </div> */}
            {/* {
              width > 992 && (
                <HeaderMenu />
              )
            } */}
            <div className={`${styles.menuContainer} d-none d-md-flex align-items-center w-full`}>
              {[...data, ...allSports?.slice(0, 2)]?.map((d, i) => {
                return (
                  <div
                    key={i}
                    className="d-flex justify-content-center align-items-center flex-column
                    ">
                    <div>
                      {d?.isAnchor ? (
                        <a href={d?.sSlug} className={`${styles.menuItem} ${d?.sSlug === router.asPath && 'font-bold'}`}>
                          {d?.sTitle}
                        </a>
                      ) : (
                        <CustomLink href={d?.sSlug}>
                          <a className={`${styles.menuItem} ${d?.sSlug === router.asPath && 'font-bold'}`}>{d?.sTitle}</a>
                        </CustomLink>
                      )}
                    </div>
                    <div
                      className={`${
                        (
                          d?.sSlug === '/football' ? router.asPath.startsWith('/football') : d?.sSlug.length > 1 ? router.asPath.startsWith(d?.sSlug) : router.asPath === '/' || router.asPath.startsWith('/cricket') || router.asPath === '/icc-rankings' || router.asPath.includes('cricket')
                        ) ? styles.selectedTriangle : ''
                      } position-absolute`}></div>
                  </div>
                )
              })}
              {allSports.length ? <CDropdown data={{ sTitle: <span className={`${styles.menuItem} ${styles.more} mx-0`}><HamburgerIcon /></span>, oChildren: allSports.slice(2, allSports?.length) || [], _id: 'sports-selection-desk' }} /> : null}
            </div>

            <Nav className={`${styles.nav} ms-auto align-items-center position-relative pb-0`}>
              <DarkModeButton />
              <div className="d-flex align-items-center">
                <HeaderUser styles={styles} />
              </div>
            </Nav>
          </Navbar>
        </Container>
      </header>

      <div className={`${styles.mobileSportsHeader} d-md-none d-flex`}>
        <Container>
          <div className="d-flex align-items-center w-full position-relative">
            {data?.map((d, i) => {
              return (
                <div
                  key={i}
                  className="d-flex justify-content-center align-items-center flex-column
                    ">
                  <div>
                      {d?.isAnchor ? (
                        <a href={d?.sSlug} className={`${styles.menuItem} ${d?.sSlug === router.asPath && 'font-bold'}`}>
                          {d?.sTitle}
                        </a>
                      ) : (
                        <CustomLink href={d?.sSlug}>
                          <a className={`${styles.menuItem} ${d?.sSlug === router.asPath && 'font-bold'}`}>{d?.sTitle}</a>
                        </CustomLink>
                      )}
                  </div>
                  <div
                      className={`${
                        (
                          d?.sSlug === '/football' ? router.asPath.startsWith('/football') : d?.sSlug.length > 1 ? router.asPath.startsWith(d?.sSlug) : router.asPath === '/' || router.asPath.startsWith('/cricket')
                        ) ? styles.selectedTriangleMobile : ''
                      } position-absolute`}></div>
                </div>
              )
            })}
            <div className='me-3'>
              {allSports.length ? <CDropdown data={{ sTitle: <span className={`${styles.menuItem} mx-0 w-50 h-50`}><HamburgerIcon /></span>, oChildren: allSports || [], _id: 'sports-selection-mob' }} /> : null}
            </div>
          </div>
        </Container>
      </div>

      <div className={styles.siteSecondaryHeader}>
        <Container>
          <div className="d-flex">
            <div className={`${styles.sgmedia} d-none d-md-flex align-items-center font-semi`}>
              <span className={`${styles.powered} overflow-hidden text-nowrap me-2`}>Powered By</span>
              <MyImage src={sgMedia} alt="SG media" width="100px" height="20px" />
            </div>
            <div className={styles.triangle}></div>
            {(router.asPath !== '/chess-2024' && router.asPath !== '/tennis' && router.asPath !== '/olympics' && router.asPath !== '/hockey') &&
              <HeaderMenu />
            }
          </div>
        </Container>
      </div>

      {/* {width < 992 && (
           <div className={`${styles.sgMediaContainer} w-full`}>
           <div className={`${styles.sgmedia} d-flex align-items-center font-semi w-full justify-content-center`}>
             <span className={`${styles.powered} overflow-hidden text-nowrap me-2`}>Powered By</span>
             <MyImage src={sgMedia} alt="SG media" width="100px" height="20px" />
           </div>
         </div>
      )} */}
      {/* SG logo for all page */}
      {/* {showMenu && <HeaderSidebar showMenu={showMenu} handleMenu={() => handleMenuClose()} />} */}
      <MobileMenu />
    </>
  )
}
export default MainHeader
